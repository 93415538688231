import React from 'react'
import clsx from 'clsx'
import NetworkSelector from '../../../../components/NetworkSelector'
import LanguageSelector from '../../../../components/LanguageSelector'
import { useAppSelector } from '../../../../hooks/hooks'

const toolbarButtonMarginClass = 'ms-1 ms-lg-3';

const Topbar: React.FC = () => {
  const token = useAppSelector((state) => state.auth.token)
  const isAuthorized = Boolean(token)

  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <w3m-button />
      </div>
      <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
        <NetworkSelector />
      </div>
      {!isAuthorized && (
        <div className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}>
          <LanguageSelector />
        </div>
      )}
    </div>
  )
}

export default Topbar
