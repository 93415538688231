import {createApi, FetchArgs} from '@reduxjs/toolkit/query/react'
import {ListResponse} from '../../../resources/ListResponse'

import {ImageWrapper} from '../../../resources/ImageWrapper'
import {BaseQueryApi} from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {baseQuery} from '../../../services/apiService'
import {FetchLaunchpadRequest} from '../resources/launchpad/FetchLaunchpadRequest'
import {LaunchpadResponseModel} from '../resources/launchpad/LaunchpadResponseModel'
import { TopIncreaseResponseModel } from '../resources/launchpad/TopIncreaseResponseModel ';
import LaunchpadRequestModel from '../resources/launchpad/LaunchpadRequestModel'
import LaunchpadUpdateAddressRequest from '../resources/launchpad/LaunchpadUpdateAddressRequest'
import {SignedLaunchpadRequestModel} from '../resources/launchpad/SignedLaunchpadRequestModel'
import { ICandlestickDataRequest, ICandlestickDataResponse } from '../resources/CandlestickData'
import { BCOwner } from '../resources/BCOwner'
import baseUrls from '../../../services/baseUrls'
import { ILaunchpadCommentDeleteRequest, ILaunchpadCommentListRequest, ILaunchpadCommentRequest,  LaunchpadCommentModel } from '../resources/launchpad/LaunchpadComment'
import { TransactionResponse } from 'ethers'
import { TransactionRequest } from '../resources/launchpad/Transaction'
import { IPaginationRequest } from '../../../resources/Pagination'
import { LaunchpadTransactionResponseModel } from '../resources/launchpad/LaunchpadTransactionResponseModel'
import { TopEarnResponseModel } from '../resources/launchpad/TopEarnResponseModel'
import { LiquidityPoolRequestModel } from '../resources/launchpad/LiquidityPool'

export interface CategoryResponse {
  name: string
  totalOfCourses: number
}


const isBlockchainAddress = (address: string): boolean => {
  return /^0x[a-fA-F0-9]{40}$/.test(address); // Valida si es una dirección Ethereum
};

function appendWrappers(atribute: string, form: FormData, wrappers: ImageWrapper[]) {
  wrappers.forEach((s) => appendImage(atribute, form, s))
}
function appendImage(atribute: string, form: FormData, wrapper: ImageWrapper) {
  const file = wrapper?.file
  appendFile(atribute, form, file)
}
function appendFile(atribute: string, form: FormData, file: File) {
  form.append(atribute, file, file.name)
}

function convertLaunchpadRequest(request: LaunchpadRequestModel): any {
  const bodyFormData = new FormData()
  Object.entries(request).forEach(([key, value]) => {
    if (key.endsWith('Covers') || key.endsWith('PreviewImage')) {
      appendWrappers(key, bodyFormData, value)
    } else if (key !== 'Id' && !(key === 'Address' && (value === undefined || value === null))) {
      bodyFormData.append(key, value)
    }
  })
  return bodyFormData
}

export const launchpadService = createApi({
  reducerPath: 'launchpad',
  tagTypes: ['launchpads'],
  baseQuery: (args: string | FetchArgs, api: BaseQueryApi, extraOptions) => {
    const baseUrl = baseUrls.earn
    return baseQuery(args, api, extraOptions, baseUrl)
  },
  endpoints: (builder) => ({
    getAllLaunchpads: builder.query<ListResponse<LaunchpadResponseModel>, FetchLaunchpadRequest>({
      query: ({ page, ...params }) => {
        const url = `/launchpads/page/${page}`;
        return {
          url,
          method: 'GET',
          params: {
            launchpadType: 'bonding_curve',
            ...params
          }
        };
      }
    }),
    getListedLaunchpads: builder.query<ListResponse<LaunchpadResponseModel>, FetchLaunchpadRequest>({
      query: ({ page, ...params}) => ({
        url: `/launchpads/listed/page/${page}`,
        method: 'GET',
        params: {
          launchpadType: 'bonding_curve',
          ...params
        },
      }),
    }),
    getAllLaunchpadsByUser: builder.query<LaunchpadResponseModel[], string>({
      query: (request) => ({
        url: `/launchpads/byprofile/${request}`,
        method: 'GET',
      }),
    }),
    getTopBuyTransactions: builder.query<ListResponse<LaunchpadTransactionResponseModel>, IPaginationRequest & { timePeriod?: string }>({
      query: ({ page, pageSize = 10, timePeriod = '24h' }) => ({
        url: `/launchpads/top-buy/${page}`,
        method: 'GET',
        params: {
          pageSize,
          timePeriod,
        },
      }),
    }),
    getTopPriceIncreaseLaunchpads: builder.query<ListResponse<TopIncreaseResponseModel>, IPaginationRequest & { timePeriod?: string }>({
      query: ({ page, pageSize = 10, timePeriod = '24h' }) => ({
        url: `/launchpads/top-increase/${page}`,
        method: 'GET',
        params: {
          pageSize,
          timePeriod,
        },
      }),
    }),
    getLaunchpadOwners: builder.query<BCOwner[], string>({
      query: (launchpadId) => ({
        url: `/launchpads/${launchpadId}/owners`,
        method: 'GET',
      }),
    }),
    getLaunchpadCategories: builder.query<CategoryResponse[], void>({
      query: () => ({
        url: `/launchpads/categories`,
        method: 'GET',
      }),
    }),

    getLaunchpadTopBuy: builder.query<CategoryResponse[], IPaginationRequest>({
      query: ({page, ...params}) => ({
        url: `/launchpads/top-buy/${page}`,
        method: 'GET',
        params
      }),
    }),
    getSingleLaunchpad: builder.query<LaunchpadResponseModel, string>({
      query: (launchpadId) => ({
        url: `/launchpads/${launchpadId}`,
        method: 'GET',
      }),
    }),
    getLaunchpadComments: builder.query<LaunchpadCommentModel[], ILaunchpadCommentListRequest>({
      query: ({launchpadId, page, pageSize}) => ({
        url: `/launchpads/${launchpadId}/comments`,
        method: 'GET',
        params:{
          pageNumber: page,
          pageSize
        }
      }),
    }),
    getTopEarners: builder.query<ListResponse<TopEarnResponseModel>, { pageNumber: number; pageSize?: number; timePeriod?: string }>({
      query: ({ pageNumber, pageSize = 10, timePeriod = '24h' }) => ({
        url: `/launchpads/top-earn/${pageNumber}`,
        method: 'GET',
        params: {
          pageSize,
          timePeriod,
        },
      }),
    }),
    getRecentComments: builder.query<ListResponse<LaunchpadCommentModel>, { pageNumber: number; pageSize?: number }>({
      query: ({ pageNumber, pageSize = 10 }) => ({
        url: `/launchpads/comments/recent/${pageNumber}`,
        method: 'GET',
        params: {
          pageSize,
        },
      }),
    }),
    getSingleLaunchpadByAddressOrId: builder.query<LaunchpadResponseModel, string>({
      query: (key) => ({
        url: isBlockchainAddress(key) ? `/launchpads/getbyaddress/${key}` : `/launchpads/${key}`,
        method: 'GET',
      }),
    }),
    getLaunchpadCandleData: builder.query<ICandlestickDataResponse[], ICandlestickDataRequest>({
      query: ({launchpadId, ...data}) => ({
        url: `/launchpads/${launchpadId}/candlestick-data?interval=minute`,
        method: 'GET',
        params: data
      }),
    }),
    createLaunchpad: builder.mutation<LaunchpadResponseModel, SignedLaunchpadRequestModel>({
      query: (newLaunchpad) => ({
        url: '/launchpads',
        method: 'POST',
        body: convertLaunchpadRequest(newLaunchpad),
      }),
    }),
    updateLaunchpad: builder.mutation<LaunchpadResponseModel, LaunchpadRequestModel>({
      query: (newLaunchpad) => ({
        url: `/launchpads/${newLaunchpad?.Id}`,
        method: 'PUT',
        body: convertLaunchpadRequest(newLaunchpad),
      }),
    }),
    updateLaunchpadAddress: builder.mutation<LaunchpadResponseModel, LaunchpadUpdateAddressRequest>(
      {
        query: (updateAddressRequest) => ({
          url: `/launchpads/update-address`,
          method: 'PUT',
          body: updateAddressRequest,
        }),
      }
    ),

    launchpadTransaction: builder.mutation<TransactionResponse, TransactionRequest>({
      query: ({launchpadId, transactionType, ...body}) => ({
        url: `/launchpads/${launchpadId}/${transactionType}`,
        method: 'POST',
        body
      }),
    }),
    createLaunchpadComment:builder.mutation<LaunchpadCommentModel, ILaunchpadCommentRequest>({
      query: ({launchpadId, ...body}) => ({
        url: `/launchpads/${launchpadId}/comments`,
        method: 'POST',
        body
      }),
    }),

    deleteLaunchpadComment:builder.mutation<LaunchpadCommentModel, ILaunchpadCommentDeleteRequest>({
      query: ({launchpadId,commentId, ...body}) => ({
        url: `/launchpads/${launchpadId}/comments/${commentId}`,
        method: 'DELETE',
        body
      }),
    }),
    getPrice: builder.query<any, void>({
      query: () => ({
        url: `/api/price/lastprice/TARAUSD`,
        method: 'GET',
      }),
    }),
    addLiquidity: builder.mutation<any,LiquidityPoolRequestModel>({
      query: (body) => ({
        url: `/liquidity-pools`,
        method: 'POST',
        body,
      }),
    })
  }),
})

export const launchpadServiceEndpoints = launchpadService.endpoints

export const {
  useCreateLaunchpadMutation,
  useUpdateLaunchpadMutation,
  useGetAllLaunchpadsQuery,
  useGetSingleLaunchpadQuery,
  useGetLaunchpadCategoriesQuery,
  useGetSingleLaunchpadByAddressOrIdQuery,
  useUpdateLaunchpadAddressMutation,
  useGetAllLaunchpadsByUserQuery,
  useLazyGetLaunchpadOwnersQuery,
  useGetLaunchpadTopBuyQuery,
  useGetRecentCommentsQuery,
  useGetListedLaunchpadsQuery,  
  useGetTopBuyTransactionsQuery,
  useGetLaunchpadCandleDataQuery,
  useLaunchpadTransactionMutation,
  useGetLaunchpadCommentsQuery,
  useGetTopEarnersQuery,
  useGetTopPriceIncreaseLaunchpadsQuery,
  useCreateLaunchpadCommentMutation,
  useDeleteLaunchpadCommentMutation,
  useGetPriceQuery,
  useAddLiquidityMutation,
} = launchpadService
