
  
  // Enum for chain IDs
  export enum UniverseChainId {
    Skale = 1482601649,
    SkaleTestnet = 37084624,
    PolygonMainnet = 137,
    PolygonMumbai = 80001,
    TaraxaMainnet = 841,
    TaraxaTestnet = 842,
    BSCMainnet = 56,
    Sepolia = 11155111,
    EthereumMainnet = 1,
    SeiTestnet = 1328,
  }
  
  // Supported chain IDs
  export const SUPPORTED_CHAIN_IDS: UniverseChainId[] = [
    UniverseChainId.Skale,
    UniverseChainId.PolygonMainnet,
    UniverseChainId.TaraxaMainnet,
    UniverseChainId.BSCMainnet,
    UniverseChainId.EthereumMainnet,
  ]
  
  export const SUPPORTED_TESTNET_CHAIN_IDS: UniverseChainId[] = [
    UniverseChainId.SkaleTestnet,
    UniverseChainId.PolygonMumbai,
    UniverseChainId.TaraxaTestnet,
    UniverseChainId.Sepolia,
    UniverseChainId.SeiTestnet,
  ]
  
  export const COMBINED_CHAIN_IDS: UniverseChainId[] = [
    ...SUPPORTED_CHAIN_IDS,
    ...SUPPORTED_TESTNET_CHAIN_IDS,
  ]
  
  // Enum for network layers
  export enum NetworkLayer {
    L1,
    L2,
  }
  
  // Interface for Chain Information
  export interface UniverseChainInfo {
    readonly id: UniverseChainId
    readonly url: string
    readonly logo: string
    readonly name: string
    readonly chainId: number
    readonly explorer: {
      name: string
      url: string
    }
    readonly nativeCurrency: {
      name: string
      symbol: string
      decimals: number
    }
    readonly networkLayer: NetworkLayer
    readonly supportsGasEstimates: boolean
  }
  
  // Chain Information for each network
  export const chainInfo: Record<UniverseChainId, UniverseChainInfo> = {
    [UniverseChainId.SeiTestnet]: {
      id: UniverseChainId.SeiTestnet,
      url: 'https://evm-rpc-testnet.sei-apis.com',
      chainId: 1328,
      logo: '',
      explorer: {
        name: 'Sei Explorer',
        url: 'https://seitrace.com',
      },
      name: 'Sei-Testnet',  
      nativeCurrency: {
        name: 'SEI',
        symbol: 'SEI',
        decimals: 18,
      },
     networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.Skale]: {
      id: UniverseChainId.Skale,
      url: 'https://mainnet.skalenodes.com/v1/green-giddy-denebola',
      chainId: 1482601649,
      logo: 'https://nebulachain.io/nebula_gaming_hub_on_skale_files/img/neb_logo.webp',
      explorer: {
        name: 'Skale Explorer',
        url: 'https://green-giddy-denebola.explorer.mainnet.skalenodes.com/',
      },
      name: 'Nebula',
      nativeCurrency: {
        name: 'sFUEL',
        symbol: 'SFUEL',
        decimals: 18,
      },
     networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.SkaleTestnet]: {
      id: UniverseChainId.SkaleTestnet,
      url: 'https://testnet.skalenodes.com/v1/lanky-ill-funny-testnet',
      chainId: 37084624,
      logo: 'https://nebulachain.io/nebula_gaming_hub_on_skale_files/img/neb_logo.webp',
      explorer: {
        name: 'Skale Testnet Explorer',
        url: 'https://lanky-ill-funny-testnet.explorer.testnet.skalenodes.com/',
      },
      name: 'Nebula Testnet',
     nativeCurrency: {
        name: 'sFUEL',
        symbol: 'SFUEL',
        decimals: 18,
      },
     networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.PolygonMainnet]: {
      id: UniverseChainId.PolygonMainnet,
      url: 'https://polygon-mainnet.infura.io/v3/de736e9690cc464cbd7000a8ec2f6fe2',
      chainId: 137,
      logo: '/media/svg/cripto/polygon-circle-purple.svg',
      explorer: {
        name: 'Polygon Explorer',
        url: 'https://polygonscan.com/',
      },
      name: 'Polygon PoS',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L2,
      supportsGasEstimates: true,
    },
    [UniverseChainId.PolygonMumbai]: {
      id: UniverseChainId.PolygonMumbai,
      url: 'https://polygon-mumbai.infura.io/v3/de736e9690cc464cbd7000a8ec2f6fe2',
      chainId: 80001,
      logo: '/media/svg/cripto/polygon-circle-purple.svg',
      explorer: {
        name: 'Mumbai Explorer',
        url: 'https://explorer-mumbai.maticvigil.com/',
      },
      name: 'Mumbai',
      nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L2,
      supportsGasEstimates: true,
    },
    [UniverseChainId.TaraxaMainnet]: {
      id: UniverseChainId.TaraxaMainnet,
      url: 'https://rpc.mainnet.taraxa.io/',
      chainId: 841,
      logo: 'https://testnet.explorer.taraxa.io/favicon.png',
      explorer: {
        name: 'Taraxa Explorer',
        url: 'https://explorer.mainnet.taraxa.io/',
      },
      name: 'Taraxa-Mainnet',
       nativeCurrency: {
        name: 'TARA',
        symbol: 'TARA',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.TaraxaTestnet]: {
      id: UniverseChainId.TaraxaTestnet,
      url: 'https://rpc.testnet.taraxa.io/',
      chainId: 842,
      logo: 'https://testnet.explorer.taraxa.io/favicon.png',
      explorer: {
        name: 'Taraxa Testnet Explorer',
        url: 'https://explorer.testnet.taraxa.io/',
      },
      name: 'Taraxa',
      nativeCurrency: {
        name: 'TARA',
        symbol: 'TARA',
        decimals: 18,
      },
        networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.BSCMainnet]: {
      id: UniverseChainId.BSCMainnet,
      url: 'https://bsc-dataseed.binance.org/',
      chainId: 56,
      logo: 'https://bscscan.com/images/favicon.png',
      explorer: {
        name: 'BSC Explorer',
        url: 'https://bscscan.com/',
      },
      name: 'Binance Smart Chain',
      nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.Sepolia]: {
      id: UniverseChainId.Sepolia,
      url: 'https://sepolia.infura.io/v3/7d2ecd41c400446585a5d7d8aa93c78d',
      chainId: 11155111,
      logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=033',
      explorer: {
        name: 'Sepolia Explorer',
        url: 'https://sepolia.etherscan.io/',
      },
      name: 'Sepolia',
      nativeCurrency: {
        name: 'SepoliaETH',
        symbol: 'SepoliaETH',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
    [UniverseChainId.EthereumMainnet]: {
      id: UniverseChainId.EthereumMainnet,
      url: 'https://eth.public-rpc.com',
      chainId: 1,
      logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=033',
      explorer: {
        name: 'Ethereum Explorer',
        url: 'https://etherscan.io/',
      },
      name: 'Ethereum',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      networkLayer: NetworkLayer.L1,
      supportsGasEstimates: true,
    },
  }
  
  // Function to check if a string is a valid Ethereum address
  export function isWeb3Address(address: string): boolean {
    const regex = /^0x[a-fA-F0-9]{40}$/
    return regex.test(address)
  }
  