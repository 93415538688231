import React, { FC, useEffect, useMemo, useState } from 'react'
import Web3Context from './Web3Context'
import Web3 from 'web3'
import { useAccount, useConnect, useSignMessage } from 'wagmi'
import { toast } from 'react-toastify'
import FusySwapFacade from '@fusyona/fusy-swap/offchain/fusy-swap-facade'
import { isSupportedChainId, SupportedChainId } from '@fusyona/fusy-swap/offchain/types'
import { newFacadeFromChainIdAndProvider } from '@fusyona/fusy-swap/offchain/fusy-swap-facade-factory'
import { RootState } from '../../setup'
import { useAppSelector } from '../../hooks/hooks'
import { chainInfo } from '../../resources/chains'
import { notifyError } from '../../utils/toasts'
import { BrowserProvider, JsonRpcProvider } from 'ethers'

const Web3ContextProvider: FC<React.PropsWithChildren<{}>> = (props) => {
  const { address, isConnected, connector } = useAccount()
  const [isLoading, setIsLoading] = useState(false)
  const [accountAddress, setAccountAddress] = useState('')
  const networkId = useAppSelector((state: RootState) => state.auth.network)
  const networkInfo = useMemo(() => chainInfo[networkId], [networkId])

  const [provider, setProvider] = useState<any>(new Web3(networkInfo.url))
  const [facade, setFacade] = useState<FusySwapFacade | undefined>(undefined)

  const { signMessageAsync } = useSignMessage()
  const signMessage = async (message: string): Promise<string> => {
    try {
      const signature = await signMessageAsync({ message })
      return signature
    } catch (error) {
      throw new Error('Error signing the message: ' + (error as Error).message)
    }
  }

  const initialProvider = () => {
    return new JsonRpcProvider(networkInfo.url);
  }

  // Actualizar el provider cuando cambia la red
  useEffect(() => {
    if (networkInfo) {
      setProvider(initialProvider())
    }
  }, [networkInfo])

  // Actualizar el facade cuando cambia la red o la conexión
  useEffect(() => {
    if (networkInfo) {
      try {
        const chainIdStr = networkInfo.chainId.toString() as SupportedChainId
        if (isSupportedChainId(chainIdStr)) {
          const facadeInstance = newFacadeFromChainIdAndProvider(chainIdStr, networkInfo.url)
          setFacade(facadeInstance)
        } else {
          throw new Error('Not Supported chainId')
        }
      } catch (error) {
        notifyError((error as Error).message || 'An error occurred while setting facade')
      }
    } else {
      toast.warn('Chain not found/supported')
    }
  }, [networkInfo, isConnected])

  useEffect(() => {
    setAccountAddress(address ?? '')
    if (isConnected && address) {
      const currentProvider = (window as any).ethereum;
      const newProvider = new BrowserProvider(currentProvider);
      setProvider(newProvider)
    } else {
      setProvider(initialProvider())
    }
  }, [address])

  return (
    <Web3Context.Provider
      value={{
        provider,
        setProvider,
        isConnected,
        setIsConnected: () => { },
        isLoading,
        setIsLoading,
        network: networkInfo,
        setNetwork: () => { }, // Implementa esta función si es necesario
        accountAddress,
        connector,
        signMessage,
        facade,
      }}
    >
      {props.children}
    </Web3Context.Provider>
  )
}

export default Web3ContextProvider
